import * as React from 'react';
import { style } from 'typestyle';

const cn = {
  root: style({
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1008,
    width: '100%',
    marginTop: 16,
    marginBottom: 32,
    padding: 8,
    boxSizing: 'border-box',
    color: 'rgb(33, 33, 33)'
  }),
  paper: style({
    height: '100%',
    padding: 16,
    boxSizing: 'border-box',
    marginTop: 16,
    marginBottom: 16,
    backgroundColor: 'rgb(255,255,255)',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: 4,
    color: 'rgba(0, 0, 0, 0.87)'
  }),
  button: style({
    color: '#fff',
    backgroundColor: '#3f51b5',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    padding: '6px 16px',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontWeight: 500,
    lineHeight: '1.75',
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'initial'
  }),
  icon: style({
    margin: 8,
    marginLeft: 0
  }),
  header: style({
    width: '100vw',
    height: 64,
    backgroundColor: 'rgb(33, 33, 33)',
    color: 'rgb(255,255,255)'
  }),
  logo: style({
    height: '100%',
    boxSizing: 'border-box',
    padding: 10
  }),
  whats: style({
    boxSizing: 'border-box',
    width: '50%',
    minWidth: 320,
    maxHeight: 320,
    paddingTop: 16,
    paddingRight: 16
  }),
  thumbnail: style({
    width: '100%'
  }),
  grid: style({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  }),
  stage: style({
    boxSizing: 'border-box',
    width: '33.3%',
    minWidth: 320,
    paddingRight: 16
  })
};

const track = (en: string) => () => {
  window.gtag?.('event', 'select_modern_browser', {
    event_label: en,
    transport_type: 'beacon'
  });
};

const CanNotUse = () => (
  <>
    <div className={cn.header}>
      <img
        src={require('./resources/logo-white.png')}
        alt="HackforPlay"
        className={cn.logo}
      />
    </div>
    <div className={cn.root}>
      <div className={cn.grid}>
        <img
          src={require('./resources/penguin-sorry.gif')}
          alt=""
          width={63}
          height={69}
          className={cn.icon}
        />
        <div>
          <h2>申し訳ありませんが、お使いのブラウザでは</h2>
          <h2>ハックフォープレイをご利用いただけません</h2>
        </div>
      </div>
      <div className={cn.paper}>
        <h3>ブラウザって？</h3>
        <p>インターネットをするためのソフトのこと</p>
        <p>
          ハックフォープレイでは <b>{` Microsoft Edge (エッジ) `}</b>または
          <b>{` Google Chrome (クローム) `}</b>
          をオススメしています
        </p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginTop: 16
          }}
        >
          <img src={require('./resources/edge.png')} alt="Edge" height={32} />
          <h2>{`Edge (エッジ) をえらぶ：`}</h2>
          <a
            target="_blank"
            href="microsoft-edge:https://www.hackforplay.xyz"
            onClick={track('Microsoft Edge')}
            className={cn.button}
          >
            {`Edge (エッジ) でひらく`}
          </a>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginTop: 16
          }}
        >
          <img
            src={require('./resources/GoogleChrome.svg')}
            alt=""
            height={32}
            className={cn.icon}
          />
          <h2>{`(クローム) をえらぶ：`}</h2>
          <a
            color="primary"
            target="_blank"
            href="https://www.google.com/intl/ja_ALL/chrome/"
            onClick={track('Google Chrome')}
            className={cn.button}
          >
            ダウンロードページへ
          </a>
        </div>
      </div>
      <div className={cn.paper}>
        <h2>ハックフォープレイとは？</h2>
        <p>
          ふつうにやってもクリアできないステージを、プログラミングで攻略していくゲームです
        </p>
        <div className={cn.grid}>
          <img
            src={require('./resources/whats1.png')}
            alt=""
            className={cn.whats}
          />
          <img
            src={require('./resources/a6d32db109509adb71237b21b31b6fd3.jpg')}
            alt=""
            className={cn.whats}
          />
        </div>
      </div>
      <div className={cn.paper}>
        <h2>プログラミングでゲームが作れる！</h2>
        <p>プログラミングをすれば、自分でゲームを作ることもできます</p>
        <p>
          作ったゲームは自分だけではなく、公開してたくさんの人にあそんでもらうこともできます
        </p>
        <p>
          <b>ゲームを作るには、パソコンが必要です</b>
        </p>
        <div className={cn.grid}>
          <div className={cn.stage}>
            <img
              src="https://i.gyazo.com/bd911ecb3ac314db80bcc11c5cc32573.gif"
              alt=""
              className={cn.thumbnail}
            />
            <b>ステージ</b>
            <p>by めんつゆ</p>
          </div>
          <div className={cn.stage}>
            <img
              src="https://i.gyazo.com/751012f372e06941160567c688daaa1e.gif"
              alt=""
              className={cn.thumbnail}
            />
            <b>どちらかな</b>
            <p>by コーダー道場金沢</p>
          </div>
          <div className={cn.stage}>
            <img
              src="https://i.gyazo.com/57cb42e1bb191fba146b17426d135f53.gif"
              alt=""
              className={cn.thumbnail}
            />
            <b>たにくんへのリクエストステージ２</b>
            <p>by コーダー道場金沢</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default CanNotUse;
