import * as React from 'react';
import * as ReactDOM from 'react-dom';
import CanNotUse from './CanNotUse';
import { isOldBrowser } from './isOldBrowser';

const app = document.getElementById('old-browser-supports');

if (!app) {
  throw new Error('#old-browser-supports container is not found');
}

if (isOldBrowser) {
  ReactDOM.render(<CanNotUse />, app);

  const handle = setInterval(() => {
    // old browser の場合は Slaask を表示しない
    const button = document.querySelector('.slaask-button');
    if (button && button.parentElement) {
      button.parentElement.removeChild(button);
      clearInterval(handle);
    }
  }, 100);
}
